import { Injectable } from '@angular/core';
import { Observable, observable, Observer } from 'rxjs';
import { constant } from '../constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  url: string = constant.url;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private _http: HttpClient) { }

  forgotPwd(data) : Observable<any> {
    
    let logInUrl: string = this.url + 'user/forgot-password'
    return this._http.post<boolean>(logInUrl,data, this.options);
  }

}