import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocationStrategy } from '@angular/common';
declare var swal: any;

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  constructor( public router: Router, private activeRoute: ActivatedRoute) {

    // if (!localStorage.getItem('authorization')) {

    //   this.router.navigate(['login']);
    // }
   }

  //  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
   canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // if(this.router.url == '/login' && localStorage.getItem('authorization')) {
    //     return false;
    // } else {
    //     return true;
    // }
  }

  ngOnInit() {
  }

  //logout
  logout() {
    let a = 1;

    swal({
      title: 'er du sikker på at du vil logge ut?',
      type: "info",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ja!',
      cancelButtonText: "NEI", 
    } ).then(()=> {
      a=2
      // swal(
      //   'logget ut',
      //   'greit',
      //   'suksess'
      // )
      localStorage.removeItem('authorization');
      this.router.navigate(['login']);

    });
}
}
