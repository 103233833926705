import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MembershipComponent } from './membership/membership.component';
import { StoresComponent } from './stores/stores.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule} from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxLoadingModule } from 'ngx-loading';
import { constant } from './constant';

// import {DatepickerModule} from 'ngx-date-picker';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MembershipComponent,
    StoresComponent,
    CreditCardComponent,
    TermsConditionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxStripeModule.forRoot(constant.stripKey),
    NgxLoadingModule.forRoot({})
    // DatepickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
