import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LogInService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private _service: LogInService, private toastr: ToastrService, private router: Router) {
  
    if (localStorage.getItem('authorization') && localStorage.getItem('authorization') != null) { 
      // determine if the user is logged in from this method , redirect back to previous screen.
        window.history.go(-1);
    }
  }

  email: string;
  pwd: string;
  userId: string = null;
  public loading = false;
  public emailId: string;
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //     if (localStorage.getItem('authorization') && localStorage.getItem('authorization') != null) { // determine if the user is logged in from this method.
  //         return true;
  //     } else {
  //       return false;
  //     }
  // }

  ngOnInit() {
  }

  //Log In for existing user
  logIn(){
      this.emailId = this.email; 
      let data : any = {
          "email": this.email.trim(),
          "password": this.pwd.trim()
      }
      this.loading = true;
      this._service.logIn(data).subscribe(x => {
        this.email = null;
        this.pwd = null;
        this.userId = x.data.id;
        localStorage.setItem('authorization', x.token);
        this.loading = false;
        if(x.data.isSubscription == true) {
          this.router.navigate(['/stores']);
        } else {
          this.router.navigate(['/membership', this.userId]);
        }
      },
      err => {
          this.toastr.error('Error', err.error.message);
          this.loading = false;
          if(err.status == 403) {
              this.email = this.emailId;
              this.pwd = null;
          }
          // if(err.status == 404) {
          //     this.email = null;
          //     this.pwd = null;
          // }
        }
      );
  }

  //remove space 
  removeSpace(event) {

      var k = event ? event.which : event.keyCode;
      if (k == 32) return false;
  }

}
