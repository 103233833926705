import { Injectable } from '@angular/core';
import { Observable, observable, Observer } from 'rxjs';
import { constant } from '../constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  url: string = constant.url;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private _http: HttpClient) { }

  getMemberships() : Observable<any> {
    
    let membershipUrl: string = this.url + 'memberships'
    return this._http.get<boolean>(membershipUrl, this.options);
  }

}