import { Injectable } from '@angular/core';
import { Observable, observable, Observer } from 'rxjs';
import { constant } from '../constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  url: string = constant.url;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private _http: HttpClient) { }

  signUp(data) : Observable<any> {
    
    let signUpUrl: string = this.url + 'user/v1/sign-up/'
    return this._http.post<boolean>(signUpUrl,data, this.options);
  }

  getUserRoles(): Observable<any> {
    
    let usersRoleUrl: string = this.url + 'users/role/'
    return this._http.get<boolean>(usersRoleUrl, this.options);
  }

  private handleError(error) {
    let errMsg: string;
    // return _throw(errMsg);
  }
}