import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MembershipComponent } from './membership/membership.component';
import { StoresComponent } from './stores/stores.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

const routes: Routes = [
	{ path: '', component: SignUpComponent },
    { path:  'login',  component:  LoginComponent},
    { path:  'sign-up',  component: SignUpComponent},
    { path:  'forgot-password',  component:  ForgotPasswordComponent},
    { path:  'reset-password',  component:  ResetPasswordComponent},
    { path:  'membership/:userId',  component:  MembershipComponent},
    { path:  'stores',  component:  StoresComponent},
    { path:  'credit-card/:userId/:membershipId',  component:  CreditCardComponent},
    { path:  'terms-conditions',  component:  TermsConditionsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
