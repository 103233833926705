import { Component, OnInit } from '@angular/core';
import { MembershipService } from '../membership/membership.service';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var swal: any;

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {
  routeParams: any;
  userId: any;

  constructor(private _service: MembershipService, public router: Router, private activeRoute: ActivatedRoute, private locationStrategy: LocationStrategy, private toastr: ToastrService) {

      if (localStorage.getItem('authorization')) { 
        
        // determine if the user is logged in from this method , if not, redirect to previous screen
        // localStorage.setItem('authorization', null);
        history.pushState(null, null, location.href);
        this.locationStrategy.onPopState(() => {
          history.pushState(null, null, location.href);
        });
      }

      //if user not authorized, redirect to login page
      if (!localStorage.getItem('authorization')) {
        
          this.router.navigate(['login']);
      }
   }

   canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if(this.router.url == '/login' && localStorage.getItem('authorization') != null) {
        return false;
    } else {
        return true;
    }
  }
  memberships: any[] = [];

  ngOnInit() {

    //get Memberships
    this.getMemberships();

    //disable back button
    // window.history.go(-1);
  }

  ngAfterViewInit(){
		
		let a = this.router.url.split("/");		
    let changeUrl = a[1];
		if(changeUrl == 'membership'){
			// this.browserId().then(res => { 	
				this.routeParams = this.activeRoute.snapshot.params;
        this.userId = this.routeParams.userId;
        // this.locationSlugId = this.routeParams.locationId;
      // }
    }
  }

  getMemberships(){

    this._service.getMemberships().subscribe(x => {

      //assign memberships
      this.memberships = x.data.membershipList;

      // this.toastr.success('Success', 'Success');
    },
      err => {

          this.toastr.error('Error', err.error.message);

          // if(err.status == 422) {
          // }
        }
    );
  }

  getMembership(membership) {

      this.router.navigate(['credit-card',this.userId,membership.id]);
  }

  //logout
  logout() {
      let a = 1;

      swal({
        title: 'er du sikker på at du vil logge ut?',
        type: "info",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ja!',
        cancelButtonText: "NEI", 
      } ).then(()=> {
        a=2
        // swal(
        //   'logget ut',
        //   'greit',
        //   'suksess'
        // )
        localStorage.removeItem('authorization');
        this.router.navigate(['login']);

      });
  }
}
